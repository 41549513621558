const imgSrc = 'https://loremflickr.com/500/500/cat';

const sampleAvatar = {
    childImageSharp: {
        gatsbyImageData: {
            layout: 'constrained',
            backgroundColor: '#f8f8f8',
            images: {
                fallback: {
                    src: imgSrc,
                    srcSet: `${imgSrc} 64w`,
                    sizes: '(min-width: 500px) 500px, 100vw',
                },
                sources: [
                    {
                        srcSet: `${imgSrc} 64w`,
                        type: 'image/jpeg',
                        sizes: '(min-width: 500px) 500px, 100vw',
                    },
                ],
            },
            width: 500,
            height: 500,
        },
    },
};
export default sampleAvatar;
