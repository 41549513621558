import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';
import { build } from '../../../Constants.json';
import {
    AuthorComponent,
    AuthorDescription,
    pageContent,
    gridContainerPostRelated,
    titlePostRelated,
    titleBiography,
    descriptionBiography,
} from './AuthorPage.module.scss';
import AuthorBio from '../../composite/AuthorBio';
import GridContainer from '../../imported/GridContainer';
import CalloutBox from '../../simple/CalloutBox';

export const fngetMorePosts = (posts, index, newIndex) =>
    posts.slice(index, newIndex);

/**
 Component that allows generate static page for author pages.
 */
const AuthorPage = ({ authorInfo }) => (
    <div className={AuthorComponent} data-test={testAttributes.AUTHORPAGE_MAIN}>
        <AuthorBio
            avatar={authorInfo.author_avatar}
            socialLinks={authorInfo.author_social}
            name={authorInfo.author_name}
            titleExpertise={authorInfo.author_titleexpertise}
            titleEducation={authorInfo.author_titleeducation}
            expertise={authorInfo.author_expertise}
            education={authorInfo.author_education}
            data-test={testAttributes.AUTHORPAGE_BIO}
        />
        <div className={AuthorDescription}>
            <div
                className={pageContent}
                data-test={testAttributes.AUTHORPAGE_CONTENT}
            >
                {authorInfo?.author_biodescription && (
                    <div>
                        <h3
                            data-test={testAttributes.AUTHORPAGE_BIOTITLE}
                            className={titleBiography}
                        >
                            Biography
                        </h3>
                        <div
                            data-test={testAttributes.AUTHORPAGE_BIODESCRIPTION}
                            className={descriptionBiography}
                        >
                            {authorInfo.author_biodescription}
                        </div>
                    </div>
                )}
                {authorInfo.editorialStandards?.title && (
                    <div
                        data-test={testAttributes.AUTHORPAGE_EDITORIALSTANDARDS}
                    >
                        <CalloutBox
                            boxType="info"
                            title={authorInfo.editorialStandards?.title}
                            content={authorInfo.editorialStandards?.content}
                        />
                    </div>
                )}
            </div>
            <h2
                data-test={testAttributes.AUTHORPAGE_TITLERELATED}
                className={titlePostRelated}
            >
                {authorInfo.titleRelated}
            </h2>

            <GridContainer
                queryArgs={{
                    posts: authorInfo.postsRelated,
                    total_found: authorInfo.postsRelated.length,
                }}
                type="button"
                gridLimit={build.defaults.POSTS_PER_PAGE}
                getMorePosts={fngetMorePosts}
                btnLabel={authorInfo.btnLabel}
                data-test={testAttributes.AUTHORPAGE_GRIDCONTAINER}
                className={gridContainerPostRelated}
                containerId={build.defaults.GRID_CONTAINER_ID}
            />
        </div>
    </div>
);
AuthorPage.propTypes = {
    /** authorInfo, data from GraphQL  */
    authorInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

AuthorPage.defaultProps = {};
export default AuthorPage;
