import * as GridContainerConstants from '../../composite/GridContainer/Constants';
import * as HeaderConstants from '../../composite/Header/Constants';
import * as FooterConstants from '../../composite/Footer/Constants';

import * as AuthorBioConstants from '../../composite/AuthorBio/Constants';
import parseHTML from '../../../utilities/parseHTML';
import { samplestandars, samplebio } from './sampleHTML';

export const testAttributes = {
    AUTHORPAGE_MAIN: 'authorpage_main',
    AUTHORPAGE_GRIDCONTAINER: 'authorpage_gridcontainer',
    AUTHORPAGE_BIO: 'authorpage_bio',
    AUTHORPAGE_CONTENT: 'authorpage_content',
    AUTHORPAGE_TITLERELATED: 'authorpage_titlerelated',
    AUTHORPAGE_EDITORIALSTANDARDS: 'authorpage_editorialstandards',
    AUTHORPAGE_BIOTITLE: 'authorpage_biotitle',
    AUTHORPAGE_BIODESCRIPTION: 'authorpage_biodescription',
};

export const sampleAttributes = {
    infoAuthor: {
        author_avatar: AuthorBioConstants.sampleAttributes.sampleAvatar,
        author_social: AuthorBioConstants.sampleAttributes.sampleSocial,
        author_name: AuthorBioConstants.sampleAttributes.sampleName,
        author_titleexpertise:
            AuthorBioConstants.sampleAttributes.sampleExpertise.title,
        author_titleeducation:
            AuthorBioConstants.sampleAttributes.sampleEducation.title,
        author_expertise:
            AuthorBioConstants.sampleAttributes.sampleExpertise.text,
        author_education:
            AuthorBioConstants.sampleAttributes.sampleEducation.text,
        postsRelated: GridContainerConstants.gridParams.samplePosts,
        btnLabel: 'SEE MORE',
        titleRelated: `More from Timmy`,
        editorialStandards: {
            title: 'LowerMyBills Editorial Standards',
            content: parseHTML(samplestandars),
        },
        author_biodescription: parseHTML(samplebio),
    },

    headerProps: {
        headerMenu: HeaderConstants.sampleAttributes.headerMenu,
        logoUrl: HeaderConstants.defaultAttributes.url,
        logoAlt: HeaderConstants.defaultAttributes.alt,
    },
    footerProps: {
        itemsMenu: FooterConstants.sampleAttributes.listMenu,
        socialLinks: FooterConstants.sampleAttributes.links,
    },
};
