import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
    AuthorComponent,
    AuthorPreamble,
    AuthorContent,
    AuthorAvatarContainer,
    AuthorAvatarImg,
    AuthorInfoContainer,
    socialIcons,
} from './AuthorBio.module.scss';
import { testAttributes } from './Constants';
import SocialLinks from '../../simple/SocialLinks';

const AuthorBio = ({
    avatar,
    socialLinks,
    name,
    expertise,
    education,
    titleExpertise,
    titleEducation,
}) => (
    <div className={AuthorComponent}>
        <div className={AuthorPreamble}>
            <div
                className={AuthorContent}
                itemScope
                itemProp="author"
                itemType="https://schema.org/Person"
                data-test={testAttributes.AUTHOR_CONTENT}
            >
                <div className={AuthorAvatarContainer}>
                    {avatar && (
                        <GatsbyImage
                            image={avatar?.childImageSharp?.gatsbyImageData}
                            className={AuthorAvatarImg}
                            alt={name}
                            itemProp="image"
                            data-test={testAttributes.AUTHOR_BIO_AVATAR}
                        />
                    )}
                </div>
                <div className={AuthorInfoContainer}>
                    <h1
                        itemProp="name"
                        data-test={testAttributes.AUTHOR_BIO_NAME}
                    >
                        {name}
                    </h1>
                    <div>
                        <strong>{titleExpertise}</strong>
                        <span data-test={testAttributes.AUTHOR_BIO_EXPERTISE}>
                            {expertise}
                        </span>
                    </div>
                    <div>
                        <strong>{titleEducation}</strong>
                        <span data-test={testAttributes.AUTHOR_BIO_EDUCATION}>
                            {education}
                        </span>
                    </div>

                    <SocialLinks
                        links={socialLinks}
                        addClass={socialIcons}
                        data-test={testAttributes.AUTHOR_BIO_SOCIAL}
                    />
                </div>
            </div>
        </div>
    </div>
);

AuthorBio.propTypes = {
    /** Eduction of the Author */
    education: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.object,
        PropTypes.any,
    ]),
    /** Title of Expertise props */
    titleExpertise: PropTypes.string,
    /** Expertise of the Author */
    expertise: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.object,
        PropTypes.any,
    ]),
    /** T
    /** Title of Education props */
    titleEducation: PropTypes.string,
    /** Name of the Author */
    name: PropTypes.string,
    /** Image; Set URL for any image would you like to test */
    avatar: PropTypes.objectOf(PropTypes.object),
    /** socialLinks, SocialLinks of the author */
    socialLinks: PropTypes.objectOf(PropTypes.any),
};

AuthorBio.defaultProps = {
    education: '',
    expertise: '',
    name: '',
    titleExpertise: '',
    titleEducation: '',
    socialLinks: {},
    avatar: null,
};

export default AuthorBio;
