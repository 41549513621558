import sampleAvatar from './sampleAvatarImage';

export const testAttributes = {
    AUTHOR_CONTENT: 'author_content',
    AUTHOR_BIO_AVATAR: 'author_bio_avatar',
    AUTHOR_BIO_NAME: 'author_bio_name',
    AUTHOR_BIO_EXPERTISE: 'author_bio_expertise',
    AUTHOR_BIO_EDUCATION: 'author_bio_education',
    AUTHOR_BIO_SOCIAL: 'author_bio_social',
};

export const sampleAttributes = {
    sampleName: 'Timmy Turner',
    sampleText:
        "Your head is not an artifact! The unexpected is our normal routine. Worf, It's better than music. It's jazz. I'm afraid I still don't understand, sir. Yes, absolutely, I do indeed concur, wholeheartedly! Is it my imagination, or have tempers become a little frayed on the ship lately? You enjoyed that. I think you've let your personal feelings cloud your judgement. Now, how the hell do we defeat an enemy that knows us better than we know ourselves? Well, that's certainly good to know. When has justice ever been as simple as a rule book? I've had twelve years to think about it. And if I had it to do over again, I would have grabbed the phaser and pointed it at you instead of them..",
    sampleExpertise: {
        title: 'Expertise:',
        text: 'Auto claims process, payment reduction, underwriting',
    },
    sampleEducation: {
        title: 'Education:',
        text:
            'The Pennsylvania State University New Kensington Campus of the Commonwealth College',
    },

    sampleSocial: {
        facebook: 'https://www.google.com',
        instagram: 'https://www.google.com',
        linkedin: 'https://www.google.com',
        twitter: 'https://www.google.com',
    },
    sampleAvatar,
};
