// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var AuthorComponent = "z_x";
export var AuthorPreamble = "z_bW";
export var AuthorContent = "z_bX";
export var AuthorAvatarImg = "z_bY";
export var AuthorInfoContainer = "z_bZ";
export var AuthorAvatarContainer = "z_b0";
export var AuthorReadMore = "z_b1";
export var socialIcons = "z_b2";
export var EditorialContainer = "z_b3";
export var AutorMoreFrom = "z_b4";