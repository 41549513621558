// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "j_h";
export var AuthorComponent = "j_x";
export var AuthorDescription = "j_y";
export var pageContent = "j_z";
export var titleBiography = "j_B";
export var descriptionBiography = "j_C";
export var gridContainerPostRelated = "j_D";
export var titlePostRelated = "j_m";