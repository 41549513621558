import DOMPurify from 'isomorphic-dompurify';
import parseHTML from '../../../utilities/parseHTML';
import { filterAndSortRelatedPosts } from '../../../utilities/gridCardFilters';
import processHeadArgs from '../../common/process/processHeadArgs';
import {
    titleRelated,
    btnLabel,
    titleExpertise,
    titleEducation,
} from '../Constants';

const processAuthorArgs = (queried) => 
{
    const authorArgs = {
        author_avatar: {},
        author_social: [],
        author_name: '',
        author_titleexpertise: '',
        author_titleeducation: '',
        author_expertise: '',
        author_education: '',
        postsRelated: [],
        btnLabel: '',
        titleRelated: `${titleRelated} Timmy`,
        editorialStandards: {},
        author_biodescription: {},
    };

    const { author, editorialStandards } = queried;

    const { description, name, localAvatar } = author;

    const {
        expertise,
        education,
        displayname,
        socialLinks,
    } = author?.cdmfields?.authorDisplay;

    authorArgs.author_avatar = localAvatar;

    authorArgs.author_social = socialLinks;

    authorArgs.author_social = socialLinks.reduce(
        (a, v) => ({ ...a, [v.channel]: DOMPurify.sanitize(v.url) }),
        {}
    );

    authorArgs.author_name = DOMPurify.sanitize(name);
    authorArgs.author_titleexpertise = DOMPurify.sanitize(titleExpertise);
    authorArgs.author_titleeducation = DOMPurify.sanitize(titleEducation);
    authorArgs.author_expertise = parseHTML(
        expertise !== undefined ? expertise : ''
    );
    authorArgs.author_education = parseHTML(
        education !== undefined ? education : ''
    );

    authorArgs.postsRelated = filterAndSortRelatedPosts(
        0,
        [...author.posts.nodes.map((node) => ({ node })), ...author.guides.nodes.map((node) => ({ node }))],
        [],
        true,
    );
    authorArgs.btnLabel = btnLabel;
    authorArgs.titleRelated = `${titleRelated} ${displayname}`;
    authorArgs.editorialStandards = editorialStandards;
    authorArgs.author_biodescription = parseHTML(description);

    return authorArgs;
};
const processQueried = (queried) => 
{
    const allArgs = {
        authorArgs: processAuthorArgs(queried),
        headArgs: processHeadArgs(
            queried.author.cdmfields.metadata,
            queried.site.siteMetadata
        ),
    };
    return allArgs;
};
export default processQueried;
